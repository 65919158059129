
:root {
  --ui1: #373737;
  --ui2: #5B5B5B;
  --ui3: #8D8D8D;
  --ui4: #BABABA;
  --ui5: #E1E1E1;
  --orange: #FF6A00;
  --orangeTint: #FFD8BD;
}

@font-face {
  font-family: "fontx";
  src: url("./assets/font.otf");
}

@font-face {
  font-family: "Arbeit";
  src: url("./assets/Arbeit-Light.woff2");
  font-weight: 300;
}
@font-face {
  font-family: "Arbeit";
  src: url("./assets/Arbeit-Book.woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Arbeit";
  src: url("./assets/Arbeit-Regular.woff2");
  font-weight: 500;
}


body {
  padding: 0;
  margin: 0;
  background: var(--ui5);
  color: var(--ui2);
  font-family: 'Arbeit', 'Helvetica Neue', sans-serif;
  font-weight: 300;
}

header {
  background: #000000;
  height: 80vh;
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
}

header .csvrs-logo {
  overflow: visible;
  width: 56px;
  height: 56px;
  height: unset;
  aspect-ratio: 1;
  grid-column: 1;
  grid-row: 1;
  transition: opacity 1s;
}
header .csvrs-logo path {
  opacity: 0;
  transition: all 0.5s;
  /* transform: scale(1.2,1.2); */
  /* transform-origin: 50%; */
}
body.loaded header .csvrs-logo path {
  opacity: 1;
}
body.loaded header .csvrs-logo path:nth-child(2) {
  transition-delay: 0.5s;
}
body.loaded header .csvrs-logo path:nth-child(3) {
  transition-delay: 1s;
}

header .dot-matrix {
  grid-column: 1;
  grid-row: 1;
  width: 56px;
  height: unset;
  aspect-ratio: 1;
  position: relative;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
}

.show-dot-matrix header .csvrs-logo {
  opacity: 0;
}
.show-dot-matrix header .dot-matrix {
  opacity: 1;
  pointer-events: all;
}

body.loaded .csvrs-logo,
body.loaded .dot-matrix {
  transition: opacity 1s;
}

.reviews {
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  color: var(--ui4)
}
.reviews li {
  /* opacity: 0; */
  position: absolute;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 20vw;
  padding: 5vw 5vw;
  min-height: 120px;
  opacity: 0;
  transition: opacity 500ms;
  /* border: 1px solid purple; */
}

.reviews .quote {
  font-size: 24px;
}
.reviews .stars {
  text-wrap: nowrap;
}
.reviews .stars img {
  height: 2rem;
  margin-right: .15rem;
}

.reviews .score {
  font-size: 42px;
  font-family: 'fontx';
}
.reviews .score span {
  font-family: 'Arbeit', sans-serif;
}

.reviews cite {
  display: block;
  font-size: 14px;
  font-style: normal;
  font-family: 'Arbeit', sans-serif;
  font-weight: 500;
}
.reviews cite::before {
  display: block;
  content: '';
  width: 1.5em;
  height: 1px;
  margin: 10px 0 5px 0;
  background: var(--ui2);

}
.reviews li[data-position] {
  opacity: 1;
}

.reviews li[data-position="1"] {
  right: 50vw;
  margin-right: 5vw;
  bottom: 50%;
}
.reviews li[data-position="3"] {
  right: 50vw;
  margin-right: 5vw;
  top: 50%;
}

.reviews li[data-position="2"] {
  left: 50vw;
  margin-left: 5vw;
  bottom: 50%;
}
.reviews li[data-position="4"] {
  left: 50vw;
  margin-left: 5vw;
  top: 50%;
}


body > section {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

section.trailer-screenshots {
  margin-top: -60px;
  position: relative;
  display: grid;
}

.trailer-screenshots lite-youtube {
  border: 1px solid #000000;  
}

.trailer-screenshots lite-youtube,
.trailer-screenshots .featured-media iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
  z-index: 10;
  max-width: unset;
  height: unset;
}
.trailer-screenshots lite-youtube:before {
  display: none;
}

lite-youtube:after {
  display: none !important;
}

.featured-media {
  background: #000000;
  display: grid;
  aspect-ratio: 16 / 9;
}
.featured-image {
  max-width: 100%;  
  aspect-ratio: 16 / 9;
  background: #000000;
}

.thumbnails {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  gap: 20px;
  margin: 20px 0;
}

.thumbnails img {
  cursor: pointer;
  aspect-ratio: 16 / 9;
}

.trailer-thumbnail {
  position: relative;
}
.trailer-thumbnail::before {
  content: '';
  display: block;
  width: 68px;
height: 48px;
cursor: pointer;
z-index: 1;
filter: grayscale();
background-color: #0000;
background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" viewBox=\"0 0 68 48\"><path fill=\"%23f00\" fill-opacity=\"0.8\" d=\"M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z\"></path><path d=\"M 45,24 27,14 27,34\" fill=\"%23fff\"></path></svg>");
border: none;
transition: filter .1s cubic-bezier(0, 0, .2, 1);
position: absolute;
top: 50%;
left: 50%;
transform: translate3d(-50%, -50%, 0);
}


section.main-content {
  display: grid;
  grid-template-columns: 1fr auto;
  max-width: 800px;
  gap: 40px;
  margin-top: 20px;
  min-height: 80vh;
  align-items: start;

}

.store-link {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  color: var(--ui2);
  line-height: 1em;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-decoration: none;
}

.store-icon {
  height: 16px;
  aspect-ratio: 1;
  background: var(--orange);
  border-radius: 100%;
  display: inline-block;
}

.store-label {
  display: grid;
  align-items: center;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 1px;
}

.store-link:hover .store-label {
  text-decoration: none;
}

section.main-content {
  font-family: Arbeit;
  font-size: 24px;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -0.02em;
  text-align: left;
}

.main-content h1, .main-content p {
  margin: 0;
  font-weight: 400;
  font-size: unset;;
}

.store-logo {
  height: 16px;
  max-width: 100%;
}

footer {
  margin-bottom: 14px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-self: flex-end;
}

footer section {
  margin-left: 0;
  margin-right: 0;
}

.logos {
  display: grid;
  align-items: center;
  align-self: end;
  max-width: 400px;
  gap: 16px;

  grid-template-columns: 2.5fr 1.4fr 1.8fr;
  align-content: baseline;
}
.logos a {
  display: grid;
}
.logos img {
  width: 100%;
}

footer section.social {
  display: grid;
  align-items: end;
}

.social h2 {
  font-size: 16px;
  font-weight: 400;
  margin-top: 25px;
}

.social-icons {
  list-style-type: none;
  padding: 0;
  margin: 0;
   
  display: flex;
  flex-direction: row;
  gap: 14px;
}
 
/* .social-icons path {
  fill: var(--ui2);
}
 
.social-icons a:hover path {
  fill: var(--ui1);
} */
 
.social-icons svg {
  width: 28px;
  height: 28px;
}
 

.press-kit {
  display: grid;
  justify-content: end;
  align-items: flex-end;
  padding-bottom: 8px;
}
.press-kit a {
  font-size: 16px;
  line-height: 1em;;
  font-weight: 400;
  color: var(--ui2);
  display: flex;
  gap: 8px;
  height: 20px;
  align-content: center;
  text-decoration: underline;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 1px;
}
.press-kit a:hover {
  text-decoration: none;
}

.ep-details {
  line-height: normal;
  grid-column: 2;
  justify-self: end;
  margin-bottom: 16px;
}
.ep-details h2, .ep-details p {
  margin: 0;
  font-size: 16px;
}
.ep-details ul, .ep-details li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.ep-details ul { 
  max-width: 80px;
  margin-top: 8px;
}
.ep-details li {
  display: inline-block;
}
.ep-details a {
  white-space: nowrap;
  color: inherit;
  font-size: 12px;
  display: block;
}
.ep-details a img {
  height: 100%;
}
.ep-details li.icon {
  height: 30px;
  aspect-ratio: 1/1;
  margin-right: 4px;
}
.ep-details li.text {
  display: block;
}

.ep-details li.icon a {
  text-decoration: none;
  display: grid;
  height: 100%;
}

.gja-badge {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 200px;
  max-width: 33vw;
}

.store-logos {
  display: flex;
  gap: 0.5em;
  max-width: 100%;
  margin-top: 20px;
}

.fine-print ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  justify-content: center;
  gap: 1em;
  margin-bottom: 20px;
}
.fine-print li {
  padding: 0;
  margin: 0;
}
.fine-print a {
  color: var(--ui2);
  font-size: 13px;
  text-underline-offset: 0.2em;
  text-decoration-thickness: 1px;
  font-weight: 400;
}
.fine-print a:hover {
  text-decoration: none;
}

@media screen and (max-width: 460px) {
  .ep-details {
    grid-column: 1 / span 2;
    margin-bottom: 32px;
    justify-self: end;
  }
}

@media screen and (max-width: 744px) {
  section.main-content {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
  body > section {
    padding-left: 16px;
    padding-right: 16px;
  }  

  .reviews li {
    min-height: unset;
    padding: 0;
    margin: 0;
  }
  .reviews li[data-position="1"] {
    left: 10vw;
    right: 10vw;
    width: 80vw;
    bottom: 100px;
  }
  .store-logos {
    max-width: 100%;
  }
  .store-logos a {
    flex-shrink: 1;
  }

  
}

@media screen and (min-width: 744px) {
  body > section {
    padding-left: 32px;
    padding-right: 32px;
  }

  header .csvrs-logo, header .dot-matrix {
    width: 64px;
    height: 64px;
  }
  section.main-content > div {
    width: 110%;
  }
  section.main-content {
    margin-top: 25px;
    font-size: 32px;
  }
  footer {
    margin-top: 60px;
    margin-bottom: 32px;
    grid-column: span 2;
  }  

}

@media screen and (min-width: 1280px) {
  body > section {
    padding-left: 148px;
    padding-right: 148px;
  }
  header .csvrs-logo, header .dot-matrix {
    width: 112px;
    height: 112px;
  }
  body > section {
    max-width: 1600px;
  }
  section.main-content {
    margin-top: 40px;
    max-width: 1600px;
  }
  footer {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 880px) {
  .store-link {
    justify-content: flex-start;
  }
  footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .logos {
    grid-column: span 2;
    justify-self: start;
  }
  .logos img {
    max-width: 100%;
  }

}

@media screen and (min-width: 880px) {
  footer {
    position: relative;
  }
  .ep-details {
    position: absolute;
    right: 0;
    bottom: 100px;
  }

}
