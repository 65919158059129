:root {
  --ui1: #373737;
  --ui2: #5b5b5b;
  --ui3: #8d8d8d;
  --ui4: #bababa;
  --ui5: #e1e1e1;
  --orange: #ff6a00;
  --orangeTint: #ffd8bd;
}

@font-face {
  font-family: fontx;
  src: url("font.96568d86.otf");
}

@font-face {
  font-family: Arbeit;
  src: url("Arbeit-Light.ab85dc99.woff2");
  font-weight: 300;
}

@font-face {
  font-family: Arbeit;
  src: url("Arbeit-Book.d6329ab8.woff2");
  font-weight: 400;
}

@font-face {
  font-family: Arbeit;
  src: url("Arbeit-Regular.760ab2d9.woff2");
  font-weight: 500;
}

body {
  background: var(--ui5);
  color: var(--ui2);
  margin: 0;
  padding: 0;
  font-family: Arbeit, Helvetica Neue, sans-serif;
  font-weight: 300;
}

header {
  height: 80vh;
  background: #000;
  justify-content: center;
  align-items: center;
  display: grid;
  position: relative;
}

header .csvrs-logo {
  width: 56px;
  height: 56px;
  height: unset;
  aspect-ratio: 1;
  grid-area: 1 / 1;
  transition: opacity 1s;
  overflow: visible;
}

header .csvrs-logo path {
  opacity: 0;
  transition: all .5s;
}

body.loaded header .csvrs-logo path {
  opacity: 1;
}

body.loaded header .csvrs-logo path:nth-child(2) {
  transition-delay: .5s;
}

body.loaded header .csvrs-logo path:nth-child(3) {
  transition-delay: 1s;
}

header .dot-matrix {
  width: 56px;
  height: unset;
  aspect-ratio: 1;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  grid-area: 1 / 1;
  position: relative;
}

.show-dot-matrix header .csvrs-logo {
  opacity: 0;
}

.show-dot-matrix header .dot-matrix {
  opacity: 1;
  pointer-events: all;
}

body.loaded .csvrs-logo, body.loaded .dot-matrix {
  transition: opacity 1s;
}

.reviews {
  width: 100%;
  height: 100%;
  color: var(--ui4);
  margin: 0;
  padding: 0;
  position: absolute;
}

.reviews li {
  width: 20vw;
  min-height: 120px;
  opacity: 0;
  margin: 0;
  padding: 5vw;
  list-style-type: none;
  transition: opacity .5s;
  position: absolute;
}

.reviews .quote {
  font-size: 24px;
}

.reviews .stars {
  text-wrap: nowrap;
}

.reviews .stars img {
  height: 2rem;
  margin-right: .15rem;
}

.reviews .score {
  font-family: fontx;
  font-size: 42px;
}

.reviews .score span {
  font-family: Arbeit, sans-serif;
}

.reviews cite {
  font-family: Arbeit, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: block;
}

.reviews cite:before {
  content: "";
  width: 1.5em;
  height: 1px;
  background: var(--ui2);
  margin: 10px 0 5px;
  display: block;
}

.reviews li[data-position] {
  opacity: 1;
}

.reviews li[data-position="1"] {
  margin-right: 5vw;
  bottom: 50%;
  right: 50vw;
}

.reviews li[data-position="3"] {
  margin-right: 5vw;
  top: 50%;
  right: 50vw;
}

.reviews li[data-position="2"] {
  margin-left: 5vw;
  bottom: 50%;
  left: 50vw;
}

.reviews li[data-position="4"] {
  margin-left: 5vw;
  top: 50%;
  left: 50vw;
}

body > section {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

section.trailer-screenshots {
  margin-top: -60px;
  display: grid;
  position: relative;
}

.trailer-screenshots lite-youtube {
  border: 1px solid #000;
}

.trailer-screenshots lite-youtube, .trailer-screenshots .featured-media iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
  z-index: 10;
  max-width: unset;
  height: unset;
  position: relative;
}

.trailer-screenshots lite-youtube:before {
  display: none;
}

lite-youtube:after {
  display: none !important;
}

.featured-media {
  aspect-ratio: 16 / 9;
  background: #000;
  display: grid;
}

.featured-image {
  max-width: 100%;
  aspect-ratio: 16 / 9;
  background: #000;
}

.thumbnails {
  width: 100%;
  gap: 20px;
  margin: 20px 0;
  display: flex;
  overflow-x: scroll;
}

.thumbnails img {
  cursor: pointer;
  aspect-ratio: 16 / 9;
}

.trailer-thumbnail {
  position: relative;
}

.trailer-thumbnail:before {
  content: "";
  width: 68px;
  height: 48px;
  cursor: pointer;
  z-index: 1;
  filter: grayscale();
  background-color: #0000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" viewBox=\"0 0 68 48\"><path fill=\"%23f00\" fill-opacity=\"0.8\" d=\"M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z\"></path><path d=\"M 45,24 27,14 27,34\" fill=\"%23fff\"></path></svg>");
  border: none;
  transition: filter .1s cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

section.main-content {
  max-width: 800px;
  min-height: 80vh;
  grid-template-columns: 1fr auto;
  align-items: start;
  gap: 40px;
  margin-top: 20px;
  display: grid;
}

.store-link {
  color: var(--ui2);
  letter-spacing: -.02em;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  line-height: 1em;
  text-decoration: none;
  display: flex;
}

.store-icon {
  height: 16px;
  aspect-ratio: 1;
  background: var(--orange);
  border-radius: 100%;
  display: inline-block;
}

.store-label {
  text-underline-offset: .2em;
  align-items: center;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  display: grid;
}

.store-link:hover .store-label {
  text-decoration: none;
}

section.main-content {
  letter-spacing: -.02em;
  text-align: left;
  font-family: Arbeit;
  font-size: 24px;
  font-weight: 400;
  line-height: 1em;
}

.main-content h1, .main-content p {
  font-weight: 400;
  font-size: unset;
  margin: 0;
}

.store-logo {
  height: 16px;
  max-width: 100%;
}

footer {
  max-width: 100%;
  justify-content: space-between;
  align-self: flex-end;
  margin-bottom: 14px;
  padding: 0;
  display: flex;
}

footer section {
  margin-left: 0;
  margin-right: 0;
}

.logos {
  max-width: 400px;
  grid-template-columns: 2.5fr 1.4fr 1.8fr;
  align-content: baseline;
  align-self: end;
  align-items: center;
  gap: 16px;
  display: grid;
}

.logos a {
  display: grid;
}

.logos img {
  width: 100%;
}

footer section.social {
  align-items: end;
  display: grid;
}

.social h2 {
  margin-top: 25px;
  font-size: 16px;
  font-weight: 400;
}

.social-icons {
  flex-direction: row;
  gap: 14px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.social-icons svg {
  width: 28px;
  height: 28px;
}

.press-kit {
  justify-content: end;
  align-items: flex-end;
  padding-bottom: 8px;
  display: grid;
}

.press-kit a {
  color: var(--ui2);
  height: 20px;
  text-underline-offset: .2em;
  align-content: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1em;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  display: flex;
}

.press-kit a:hover {
  text-decoration: none;
}

.ep-details {
  grid-column: 2;
  justify-self: end;
  margin-bottom: 16px;
  line-height: normal;
}

.ep-details h2, .ep-details p {
  margin: 0;
  font-size: 16px;
}

.ep-details ul, .ep-details li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.ep-details ul {
  max-width: 80px;
  margin-top: 8px;
}

.ep-details li {
  display: inline-block;
}

.ep-details a {
  white-space: nowrap;
  color: inherit;
  font-size: 12px;
  display: block;
}

.ep-details a img {
  height: 100%;
}

.ep-details li.icon {
  height: 30px;
  aspect-ratio: 1 / 1;
  margin-right: 4px;
}

.ep-details li.text {
  display: block;
}

.ep-details li.icon a {
  height: 100%;
  text-decoration: none;
  display: grid;
}

.gja-badge {
  width: 200px;
  max-width: 33vw;
  position: absolute;
  top: 16px;
  right: 16px;
}

.store-logos {
  max-width: 100%;
  gap: .5em;
  margin-top: 20px;
  display: flex;
}

.fine-print ul {
  justify-content: center;
  gap: 1em;
  margin: 0 0 20px;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.fine-print li {
  margin: 0;
  padding: 0;
}

.fine-print a {
  color: var(--ui2);
  text-underline-offset: .2em;
  font-size: 13px;
  font-weight: 400;
  text-decoration-thickness: 1px;
}

.fine-print a:hover {
  text-decoration: none;
}

@media screen and (max-width: 460px) {
  .ep-details {
    grid-column: 1 / span 2;
    justify-self: end;
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 744px) {
  section.main-content {
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
  }

  body > section {
    padding-left: 16px;
    padding-right: 16px;
  }

  .reviews li {
    min-height: unset;
    margin: 0;
    padding: 0;
  }

  .reviews li[data-position="1"] {
    width: 80vw;
    bottom: 100px;
    left: 10vw;
    right: 10vw;
  }

  .store-logos {
    max-width: 100%;
  }

  .store-logos a {
    flex-shrink: 1;
  }
}

@media screen and (min-width: 744px) {
  body > section {
    padding-left: 32px;
    padding-right: 32px;
  }

  header .csvrs-logo, header .dot-matrix {
    width: 64px;
    height: 64px;
  }

  section.main-content > div {
    width: 110%;
  }

  section.main-content {
    margin-top: 25px;
    font-size: 32px;
  }

  footer {
    grid-column: span 2;
    margin-top: 60px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1280px) {
  body > section {
    padding-left: 148px;
    padding-right: 148px;
  }

  header .csvrs-logo, header .dot-matrix {
    width: 112px;
    height: 112px;
  }

  body > section {
    max-width: 1600px;
  }

  section.main-content {
    max-width: 1600px;
    margin-top: 40px;
  }

  footer {
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 880px) {
  .store-link {
    justify-content: flex-start;
  }

  footer {
    grid-template-columns: 1fr 1fr;
    display: grid;
  }

  .logos {
    grid-column: span 2;
    justify-self: start;
  }

  .logos img {
    max-width: 100%;
  }
}

@media screen and (min-width: 880px) {
  footer {
    position: relative;
  }

  .ep-details {
    position: absolute;
    bottom: 100px;
    right: 0;
  }
}

lite-youtube {
  contain: content;
  cursor: pointer;
  max-width: 720px;
  background-color: #000;
  background-position: center;
  background-size: cover;
  display: block;
  position: relative;
}

lite-youtube:before {
  content: "";
  height: 60px;
  width: 100%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==");
  background-position: top;
  background-repeat: repeat-x;
  padding-bottom: 50px;
  transition: all .2s cubic-bezier(0, 0, .2, 1);
  display: block;
  position: absolute;
  top: 0;
}

lite-youtube:after {
  content: "";
  padding-bottom: 56.25%;
  display: block;
}

lite-youtube > iframe {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}

lite-youtube > .lty-playbtn {
  width: 68px;
  height: 48px;
  cursor: pointer;
  z-index: 1;
  filter: grayscale();
  background-color: #0000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" version=\"1.1\" viewBox=\"0 0 68 48\"><path fill=\"%23f00\" fill-opacity=\"0.8\" d=\"M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z\"></path><path d=\"M 45,24 27,14 27,34\" fill=\"%23fff\"></path></svg>");
  border: none;
  transition: filter .1s cubic-bezier(0, 0, .2, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

lite-youtube:hover > .lty-playbtn, lite-youtube .lty-playbtn:focus {
  filter: none;
}

lite-youtube.lyt-activated {
  cursor: unset;
}

lite-youtube.lyt-activated:before, lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.lyt-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  white-space: nowrap;
  width: 1px;
  position: absolute;
  overflow: hidden;
}

/*# sourceMappingURL=index.1d1b7637.css.map */
